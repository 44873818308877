import { defineStore } from 'pinia'

export const useCurrentMenu = defineStore('useCurrentMenu', () => {
  /**
   * ! Pinia State !
   *
   * @param currentMenu 현재 메뉴
   *
   */
  const currentMenu = ref('')

  return {
    currentMenu,
  }
}, {
  persist: {
    storage: persistedState.localStorage,
  },
})
