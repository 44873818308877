import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as policyNKohT57BqpMeta } from "/vercel/path0/pages/policy.vue?macro=true";
import { default as pressJq1FmNOBviMeta } from "/vercel/path0/pages/press.vue?macro=true";
import { default as recruitgDZ77SdzbDMeta } from "/vercel/path0/pages/recruit.vue?macro=true";
import { default as serviceDnESbXkBFhMeta } from "/vercel/path0/pages/service.vue?macro=true";
export default [
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/vercel/path0/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "press",
    path: "/press",
    component: () => import("/vercel/path0/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "recruit",
    path: "/recruit",
    component: () => import("/vercel/path0/pages/recruit.vue").then(m => m.default || m)
  },
  {
    name: "service",
    path: "/service",
    component: () => import("/vercel/path0/pages/service.vue").then(m => m.default || m)
  }
]