<script setup lang="ts">
const { t } = useLocale()
const { path } = useRoute()

const seoTitle = 'GoodNodes'
const seoDescription = '상호작용을 통해 다양한 잠재 가치들을 현실로 만드는 굿노드 주식회사입니다.'
const seoUrl = 'https://www.goodnodes.biz'
const seoImage = 'https://www.goodnodes.biz/image/image-og.jpg'

useHead({
  titleTemplate: (title: string | undefined) => {
    let returnTitle = ''

    title && !title?.includes(t('pageTitle.goodnodes').concat(' | '))
      ? returnTitle = t('pageTitle.goodnodes').concat(' | ', title)
      : returnTitle = ''
    return returnTitle
  },
})

if (import.meta.server) {
  useHead({
    htmlAttrs: {
      lang: 'ko',
    },
    meta: () => [
      { name: 'msapplication-TileColor', content: '#0EA5E9' },
      { name: 'theme-color', content: '#0EA5E9' },
    ],
    link: [
      { rel: 'canonical', href: seoUrl },
      { rel: 'icon', type: 'image/png', href: '/image/logo.png' },
      { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
      { rel: 'apple-touch-icon', sizes: '180x180', href: '/image/apple-touch-icon.png' },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/image/favicon-32x32.png' },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/image/favicon-16x16.png' },
      { rel: 'manifest', href: '/manifest.webmanifest' },
      { rel: 'mask-icon', href: '/image/safari-pinned-tab.svg', color: '#0EA5E9' },
    ],
  })
}

useSeoMeta({
  charset: 'utf-16',
  viewport: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0',
  formatDetection: 'telephone=no',
  themeColor: '#0EA5E9',
  keywords: 'goodnodes,모터사이클,오토바이,할리데이비슨,바이크,motorcycle,harleydavidson,harley,honda,혼다,ducati,두카티,bmw,비엠더블유,ktm,케이티엠,yamaha,야마하,royalenfield,로얄엔필드,custom,커스텀,커스텀바이크,custombike,custombikes,커스텀바이크',
  title: seoTitle,
  author: 'goodNodes',
  description: seoDescription,
  ogType: 'website',
  ogTitle: seoTitle,
  ogDescription: seoDescription,
  ogUrl: seoUrl,
  ogImage: seoImage,
  ogImageSecureUrl: seoImage,
  ogImageType: 'image/png',
  ogImageWidth: '410',
  ogImageHeight: '200',
  twitterCard: 'summary_large_image',
  twitterTitle: seoTitle,
  twitterDescription: seoDescription,
  twitterImage: seoImage,
})

const { currentMenu } = storeToRefs(useCurrentMenu())

watchEffect(() => {
  currentMenu.value = path
})
</script>

<template>
  <div>
    <NuxtLayout>
      <div>
        <NuxtLoadingIndicator
          color="repeating-linear-gradient(to right,#bdbdbd 0%,#cb8a80 100%)"
          :height="5"
        />
        <NuxtPage />
        <GBNotifications />
      </div>
    </NuxtLayout>
  </div>
</template>
